import * as React from 'react';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Layout } from '../common/layout';
import { Seo } from '../common/seo';
import { debounce } from '../../utils/debounce';
import { submitForm, validateEmail, FORM_TYPE } from '../../utils/forms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '50px 30px'
    },
    statusText: {
      marginTop: 5,
      fontSize: theme.typography.pxToRem(13)
    },
    innerContainer: {
      maxWidth: 1000,
      width: '100%',
    },
    title: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50)
    },
    description: {
      margin: '20px 0',
      fontSize: theme.typography.pxToRem(20)
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(2),
         width: '100%'
     },
   },
   button: {
     backgroundColor: theme.palette.primary.main,
     color: theme.palette.text.primary,
     textTransform: 'lowercase',
     alignSelf: 'flex-end',
     marginTop: 10,
     '&:hover': {
       backgroundColor: theme.palette.primary.main,
       opacity: 0.95
     },
     '&:active': {
       boxShadow: 'none',
       backgroundColor: theme.palette.primary.main,
     },
     '&:focus': {
       boxShadow: 'none',
     },
   },
   formContainer: {
     maxWidth: 600
   }
  })
);

const EMAIL_DEBOUNCE = 350;
const ERROR_KEY = 'unable to complete request, please try submitting again later';
const SUCCESS_KEY = 'we appreciate that you have taken the time to write us. ' +
                    'a member of our team will be in touch shortly :) !';

export const ContactPage = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [msgValidationWarning, setMsgValidationWarning] = React.useState(false);
  const [emailValidationWarning, setEmailValidationWarning] = React.useState(false);
  const [nameValidationWarning, setNameValidationWarning] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const validateEmailChange = React.useCallback(debounce((email) => {
    const isValid = validateEmail(email, true);
    setEmailValidationWarning(!isValid)
  }, EMAIL_DEBOUNCE), [])

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmailChange(value);
    if (!value) {
      setEmailValidationWarning(false);
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
    setNameValidationWarning(false);
  };

  const onMsgChange = (e) => {
    setMsg(e.target.value);
    setMsgValidationWarning(false);
  };

  const onSubmit = async () => {
    let hasErrors = false;
    if (!name.trim()) {
      setNameValidationWarning(true);
      hasErrors = true;
    }

    if (!email) {
      setEmailValidationWarning(true);
      hasErrors = true;
    }

    if (!msg.trim()) {
      setMsgValidationWarning(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      setIsSubmitting(true);
      await submitForm({ name, email, message: msg }, FORM_TYPE.CONTACT);
      setEmail('');
      setName('');
      setMsg('');
      setStatusMessage(SUCCESS_KEY);
    } catch(e) {
      setStatusMessage(ERROR_KEY);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Layout>
      <Seo title='Contact Us' />
      <Box className={classes.container} display="flex" justifyContent="center">
        <Box className={classes.innerContainer} display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.title}>reach out</Typography>
          <Typography className={classes.description}>
            If you have any questions, feedback, want to report a bug, or
            would like to join the team, please write us below: 
          </Typography>
          <Box className={classes.formContainer} flexDirection="column" display="flex" justifyContent="flex-end">
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                label="first name"
                variant="outlined"
                error={nameValidationWarning}
                value={name}
                onChange={onNameChange}
              />
              <TextField
                label="email"
                variant="outlined"
                type='email'
                autoComplete='email'
                error={emailValidationWarning}
                value={email}
                onChange={onEmailChange}
              />
              <TextField
                label="message"
                multiline
                rows={10}
                variant="outlined"
                error={msgValidationWarning}
                value={msg}
                onChange={onMsgChange}
              />
            </form>
            {statusMessage &&
              <Typography className={classes.statusText}>
              {statusMessage}
              </Typography>
            }
            <Button
              className={classes.button}
              variant="contained"
              disabled={isSubmitting}
              onClick={onSubmit}
            >
              <Typography style={{ fontWeight: 600 }}>
                submit
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
